import { MutationTree } from 'vuex';
import { Class } from '@/domain/Class';
import { ClassState } from './types';
import { CategoryDefinition } from '@/api/core/course.api';
import Vue from 'vue';

export const mutations: MutationTree<ClassState> = {
  setHasDownloaded(state: ClassState, flag: boolean) {
    state.hasDownloaded = flag;
  },
  setIsDownloading(state: ClassState, flag: boolean) {
    state.isDownloading = flag;
  },
  setClassList(state: ClassState, newList: Array<Class>) {
    state.classes = newList;
  },
  setClassCategories(
    state: ClassState,
    entry: { xref: string; categories: CategoryDefinition[] }
  ) {
    Vue.set(state.classCategories, entry.xref, entry.categories);
  },
};
