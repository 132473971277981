import { AclPermissionType } from '@/domain/Acls';
import { IBuilderContent } from '@/domain/Content';
import { ProblemDefinition } from '@/domain/Problem';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { ITutorStrategy } from '@/domain/Tutoring';
import { useStore } from '@/store';
import { isWip } from '@/utils/builder.util';
import { computed } from 'vue';

const store = useStore();

export const problemsMap = computed((): Record<string, ProblemDefinition> => {
  return store.state.content.problemMap;
});

export const problemSetsMap = computed(
  (): Record<string, ProblemSetDefinition> => {
    return store.state.content.problemSetMap;
  }
);

export const tutorStrategiesMap = computed(
  (): Record<string, ITutorStrategy> => {
    return store.state.content.tutorStrategyMap;
  }
);

function getTarget(xref: string): IBuilderContent | undefined {
  return (
    problemsMap.value[xref] ??
    problemSetsMap.value[xref] ??
    tutorStrategiesMap.value[xref]
  );
}

export function getWipTarget<T extends IBuilderContent>(
  xref: string
): T | undefined {
  const target = getTarget(xref);
  let wip = undefined;
  if (target?.permissions.includes(AclPermissionType.UPDATE)) {
    if (isWip(xref)) {
      wip = target;
    } else if (target.mappedCeri) {
      wip = getTarget(target.mappedCeri);
    }
  }
  return (wip ?? target) as T;
}
